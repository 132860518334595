<template>
  <div>
    <div v-if="loading" class="flex sm12 lg12">
      <div class="text--center pb-4">
        <div class="flex-center spinner-box">
          <spinner/>
        </div>
      </div>
    </div>
    <template v-else>
      <div v-if="lineData" class="cards">
        <div class="cards-container row d-flex wrap align--start">
          <well-card v-if="wellData"
            :card-class="'flex xs12'"
            :id="wellData.id"
            :name="wellData.name"
            :volume="wellCurrentData.volume"
            :volume-flow="wellCurrentData.volume_flow"
            :events="wellCurrentData.events"
            @setWellAction="setWellAction"
          />
          <overview-card v-if="mixingUnitData"
            context="irrigation"
            :card-class="'flex xs12'"
            :id="mixingUnitData.id"
            :name="mixingUnitData.name"
            :settings-now="mixingUnitCurrentData"
            :progress="progress[mixingUnitData.id]"
            @setAction="setAction"
          />
          <template v-for="irrigation in lineData">
            <overview-card :key="irrigation.id"
              context="irrigation"
              :card-class="'flex xs6'"
              :id="irrigation.id"
              :name="irrigation.name"
              :settings-now="lineCurrentData[irrigation.id]"
              :progress="progress[irrigation.id]"
              @setAction="setAction"
            />
          </template>
          <template>
            <irrigation-map
              :mapName="$t('irrigation.irrigationMap')"
              :irrigation-map-loading="irrigationMapLoading"
              :timeline-start-date="irrigationMapStartDate"
              :timeline-end-date="irrigationMapEndDate"
              :duration-days="irrigationMapDurationDays"
              :work-order-info="workOrderInfo"
              :irrigation-map="irrigationMap"
              :editable="false"
              :data-point="'day'"
              context="room"
            >
              <va-tabs
                class="room-tabs"
                slot="tabs"
                v-model="tabValue"
                center
              >
                <va-tab v-for="controlGroup in controlGroupsData" :key="controlGroup.id">
                  {{controlGroup.name}}
                </va-tab>
              </va-tabs>

            </irrigation-map>
          </template>
        </div>
      </div>
      <!-- Modal start, pause and stop -->
      <va-modal
        ref="modal"
        v-model="showModal"
        :title="modalTitle"
        :message="modalMessage"
        :okText=" $t('modal.confirm') "
        :cancelText=" $t('modal.cancel') "
        @ok="confirmAction()"
        @cancel="closeModal"
      >
      </va-modal>
      <!-- Add new modal -->
      <va-modal
        ref="addmodal"
        v-model="showAddNewModal"
        :noOutsideDismiss="true"
        :hideDefaultActions="true"
        size="large"
      >

        <template v-slot:header>
          <h6>{{ modalSubTitle }}</h6>
          <h4>{{ modalTitle }}</h4>
        </template>

        <template>
          <form>
            <div class="flex xs12">
              <div class="flex md12 xs12 pb-3">
                <va-select
                  :label="$t('tanks.actions.select_action').toUpperCase()"
                  v-model="selectedTankAction"
                  textBy="selectionActionName"
                  :options="wellPossibleActions"
                  :error="!!actionSelectionErrors"
                  :placeholder="actionSelectionErrors"
                />
              </div>

              <div class="row">
                <div v-for="(setting, name) in selectedTankAction.actionSettings" :key="name" :class="'flex md6 xs12 pb-3 pr-3'">
                  <va-select v-if="name === 'originTank'"
                    :label="$t('tanks.actions.originTank').toUpperCase()"
                    v-model="selectedData.originTank"
                    textBy="description"
                    :options="selectedTankAction.originTankOptions"
                    :error="!!originTankErrors"
                    :placeholder="originTankErrors"
                  />
                  <va-select v-if="name === 'destinationTank'"
                    :label="$t('tanks.actions.destinationTank').toUpperCase()"
                    v-model="selectedData.destinationTank"
                    textBy="description"
                    :options="selectedTankAction.destinationTankOptions"
                    :error="!!destinationTankErrors"
                    :placeholder="destinationTankErrors"
                  />

                  <va-input v-if="name === 'duration'"
                    :label="$t(`tanks.actions.${name}`).toUpperCase() + ' sec'"
                    v-model="selectedData.duration"
                    type="number"
                    step="1"
                    placeholder="0"
                    min="0"
                    :error="!!durationErrors.length"
                    :error-messages="durationErrors"
                  />

                  <va-input v-if="name === 'volume'"
                    :label="$t(`tanks.actions.${name}`).toUpperCase() + ' ' + getUnitFormated('m3') "
                    v-model="selectedData.volume"
                    type="number"
                    step="1"
                    placeholder="0"
                    min="0"
                    :error="!!volumeErrors.length"
                    :error-messages="volumeErrors"
                  />
                </div>
              </div>
            </div>
          </form>
        </template>

        <template slot="actions">
          <div class="row">
            <div class="flex xs12 text-right">
              <va-button @click="closeModal()" flat color="gray">{{ $t('modal.cancel') }}</va-button>
              <va-button @click="confirmAddAction()" color="success" class="mr-0">{{ $t('modal.confirm') }}</va-button>
            </div>
          </div>
        </template>

      </va-modal>
    </template>
  </div>
</template>

<script>
import * as socketio from '../../../utilities/socketio'
import moment from 'moment-timezone'
import { styleHelpers } from '@/services/atmosphere-ui'
import { tanksActions } from '../../../data/tanksSettings'
import WellCard from '../../../services/atmosphere-ui/components/cards/at-well-card/AtWellCard'
import OverviewCard from '../../../services/atmosphere-ui/components/cards/at-overview-card/AtOverviewCard'
import IrrigationMap from '@/services/atmosphere-ui/components/at-irrigation-map/AtIrrigationMap'

export default {
  name: 'irrigation-overview-cards',
  data () {
    return {
      loading: false,
      irrigationMapLoading: true,

      lineData: null,
      lineDataNames: ['ph', 'electrical_conductivity', 'volume', 'volume_flow'],
      lineCurrentData: {},

      wellData: null,
      wellDataNames: ['volume', 'volume_flow'],
      wellCurrentData: {},
      wellPossibleActions: [],

      mixingUnitData: null,
      mixingUnitDataNames: ['ph', 'electrical_conductivity', 'volume', 'volume_flow'],
      mixingUnitCurrentData: {},

      showModal: false,
      showAddNewModal: false,
      modalTitle: '',
      modalSubTitle: '',
      modalMessage: '',

      selectedControlActionContext: '',
      selectedControlActionLineId: '',
      selectedControlAction: '',
      selectedTankAction: '',
      selectedActionKey: '',
      selectedData: {
        originTank: '',
        destinationTank: '',
        duration: '',
        volume: '',
      },

      actionSelectionErrors: '',
      originTankErrors: '',
      destinationTankErrors: '',
      durationErrors: [],
      volumeErrors: [],

      progress: {},

      lineSettings: {},
      wellSettings: {},
      irrigationMap: {},
      controlGroupsData: null,
      selectedControlGroupId: parseInt(process.env.VUE_APP_FARM_ID),
      tabValue: 0,
    }
  },
  created () {
    this.$level.value = 'airplane'
    this.initializeView()
  },
  computed: {
    activeFarm () {
      return this.$farmId ? this.$farmId : parseInt(process.env.VUE_APP_FARM_ID)
    },
    formReady () {
      return this.actionSelectionErrors === '' &&
        this.originTankErrors === '' &&
        this.destinationTankErrors === '' &&
        !this.durationErrors.length &&
        !this.volumeErrors.length
    },
    irrigationMapStartDate () {
      if (this.selectedControlGroupId && this.controlGroupsData) {
        const controlGroupData = this.controlGroupsData.find(element => element.id === this.selectedControlGroupId)

        const first = controlGroupData.control_areas.reduce((previous, current) => {
          if ((previous.work_order_end_date === '' && current.work_order_end_date === '') || (previous.work_order_end_date === null && current.work_order_end_date === null)) {
            return current
          } else if (previous.work_order_start_date === '' || previous.work_order_start_date === null || previous.work_order_name === 'Semina/taglio finto') {
            return current
          } else if (current.work_order_start_date === '' || current.work_order_start_date === null || current.work_order_name === 'Semina/taglio finto') {
            return previous
          }
          return moment(previous.work_order_start_date).diff(moment(current.work_order_start_date)) <= 0 ? previous : current
        })
        if (first.work_order_start_date) {
          return first.work_order_start_date
        }
      }

      return moment.utc().subtract(4, 'days').hours(0).minutes(0).format().slice(0, -1)
    },
    irrigationMapEndDate () {
      if (this.selectedControlGroupId && this.controlGroupsData) {
        const controlGroupData = this.controlGroupsData.find(element => element.id === this.selectedControlGroupId)

        const last = controlGroupData.control_areas.reduce((previous, current) => {
          if ((previous.work_order_end_date === '' && current.work_order_end_date === '') || (previous.work_order_end_date === null && current.work_order_end_date === null)) {
            return current
          } else if (previous.work_order_end_date === '' || previous.work_order_end_date === null || previous.work_order_name === 'Semina/taglio finto') {
            return current
          } else if (current.work_order_end_date === '' || current.work_order_end_date === null || current.work_order_name === 'Semina/taglio finto') {
            return previous
          }
          return (moment(previous.work_order_end_date).diff(moment(current.work_order_end_date)) >= 0 ? previous : current)
        })
        if (last.work_order_start_date) {
          return last.work_order_end_date
        }
      }

      return moment.utc().format().slice(0, -1)
    },
    irrigationMapDurationDays () {
      return moment(this.irrigationMapEndDate).diff(this.irrigationMapStartDate, 'days') + 1
    },
    workOrderInfo () {
      let info = []
      if (this.controlGroupsData) {
        const controlGroupData = this.controlGroupsData.find(element => element.id === this.selectedControlGroupId)
        for (let i = 0; i < controlGroupData.control_areas.length; i++) {
          info = [
            ...info,
            {
              id: controlGroupData.control_areas[i].id,
              name: controlGroupData.control_areas[i].name,
              work_order_id: controlGroupData.control_areas[i].work_order_id,
              work_order_key: controlGroupData.control_areas[i].work_order_key,
              work_order_name: controlGroupData.control_areas[i].work_order_name,
              work_order_start_date: controlGroupData.control_areas[i].work_order_start_date,
              work_order_end_date: controlGroupData.control_areas[i].work_order_end_date,
              production_growing_recipe: controlGroupData.control_areas[i].production_growing_recipe,
            },
          ]
        }
      }

      return { [this.selectedControlGroupId]: info }
    },
  },
  methods: {
    initializeView () {
      this.loading = true
      socketio.initialiseSocket()
      socketio.socket.emit('get_all_irrigation_lines', this.activeFarm, { nested: true })
      socketio.socket.on('get_all_irrigation_lines_response', data => {
        const parse = JSON.parse(data)
        if (parse.status / 10 === 4) {
          console.log(parse.message)
          this.notFound = true
        } else {
          this.lineData = parse.data.data.filter(element => element.type === 'irrigation-line').sort((a, b) => (a.id > b.id ? 1 : -1))
          this.wellData = parse.data.data.find(element => element.type === 'well')
          this.mixingUnitData = parse.data.data.find(element => element.type === 'mixing-unit')
          this.loading = false
          this.initializeCards()
        }
      })
    },
    initializeCards () {
      for (let i = 0; i < this.lineData.length; i++) {
        this.lineCurrentData = {
          ...this.lineCurrentData,
          [this.lineData[i].id]: {},
        }
        for (let j = 0; j < this.lineDataNames.length; j++) {
          this.lineCurrentData[this.lineData[i].id] = {
            ...this.lineCurrentData[this.lineData[i].id],
            [this.lineDataNames[j]]: {},
          }
        }
        this.listenLineCurrentData(this.lineData[i].id)
      }

      if (this.wellData) {
        for (let i = 0; i < this.wellDataNames.length; i++) {
          this.wellCurrentData = {
            ...this.wellCurrentData,
            [this.wellDataNames[i]]: {},
          }
        }
        this.listenWellCurrentData()
        this.getWellPossibleActions()
      } else if (this.mixingUnitData) {
        for (let i = 0; i < this.mixingUnitDataNames.length; i++) {
          this.mixingUnitCurrentData = {
            ...this.mixingUnitCurrentData,
            [this.mixingUnitDataNames[i]]: {},
          }
        }
        this.listenMixingUnitCurrentData()
      }

      this.getAllControlGroups()
      this.getControlGroupIrrigationMap(this.selectedControlGroupId, this.irrigationMapStartDate, this.irrigationMapEndDate)
      this.listenIrrigationMapUpdate()
    },
    getAllControlGroups () {
      socketio.initialiseSocket()
      socketio.socket.emit('get_all_control_groups', this.activeFarm, { per_page: 15, nested: true })
      socketio.socket.once('get_all_control_groups_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
          this.notFound = true
        } else {
          this.controlGroupsData = (parsed.data.data).sort((a, b) => (a.id > b.id ? 1 : -1))
          for (let i = 0; i < this.controlGroupsData.length; i++) {
            socketio.socket.emit('subscribe_to_context', [`control_group_${this.controlGroupsData[i].id}`])
          }
        }
      })
    },
    getControlGroupIrrigationMap (controlGroupId, start, end) {
      this.irrigationMapLoading = true
      socketio.initialiseSocket()
      socketio.socket.emit('get_control_group_irrigation_map', this.activeFarm, controlGroupId, { from: start, to: end })
      socketio.socket.on('get_control_group_irrigation_map_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          if (parsed.data.control_group_id === controlGroupId) {
            if (parsed.data.data[controlGroupId]) {
              this.irrigationMap = parsed.data
              this.irrigationMapLoading = false
            }
          }
        }
      })
    },
    listenIrrigationMapUpdate () {
      socketio.initialiseSocket()
      socketio.socket.on('control_group_irrigation_map_update', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          if (parsed.data.control_group_id === this.selectedControlGroupId) {
            this.getControlGroupIrrigationMap(this.selectedControlGroupId, this.irrigationMapStartDate, this.irrigationMapEndDate)
          }
        }
      })
    },
    listenLineCurrentData (irrigationLineId) {
      socketio.initialiseSocket()
      socketio.socket.emit('subscribe_to_context', [`irrigation_line_${irrigationLineId}`])
      socketio.socket.on('irrigation_line_irrigation_conditions_current_update', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          if (parsed.data.irrigation_line_id === irrigationLineId) {
            for (let j = 0; j < this.lineDataNames.length; j++) {
              this.lineCurrentData[irrigationLineId][this.lineDataNames[j]] = parsed.data.data[this.lineDataNames[j]]
            }
            this.progress = {
              ...this.progress,
              [irrigationLineId]: parsed.data.data.progress,
            }
          }
        }
      })
    },
    listenWellCurrentData () {
      socketio.initialiseSocket()
      socketio.socket.emit('subscribe_to_context', [`irrigation_line_${this.wellData.id}`])
      socketio.socket.on('irrigation_line_irrigation_conditions_current_update', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          if (parsed.data.irrigation_line_id === this.wellData.id) {
            for (let j = 0; j < this.wellDataNames.length; j++) {
              this.wellCurrentData[this.wellDataNames[j]] = parsed.data.data[this.wellDataNames[j]]
            }
            this.wellCurrentData.events = parsed.data.data.events
          }
        }
      })
    },
    listenMixingUnitCurrentData () {
      socketio.initialiseSocket()
      socketio.socket.emit('subscribe_to_context', [`tank_${this.mixingUnitData.id}`])
      socketio.socket.on('tank_water_conditions_current_update', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          if (parsed.data.tank_id === this.mixingUnitData.id) {
            for (let j = 0; j < this.mixingUnitDataNames.length; j++) {
              this.mixingUnitCurrentData[this.mixingUnitDataNames[j]] = parsed.data.data[this.mixingUnitDataNames[j]] ? parsed.data.data[this.mixingUnitDataNames[j]] : {}
            }
          }
        }
      })
    },
    getWellPossibleActions () {
      this.wellPossibleActions = JSON.parse(JSON.stringify(tanksActions.well.data))
      for (let i = 0; i < this.wellPossibleActions.length; i++) {
        this.wellPossibleActions[i].selectionActionName = this.$t(`tanks.actions.${this.wellPossibleActions[i].actionName}`)
        if (this.wellPossibleActions[i].actionName === 'fill_water_tank') {
          this.wellPossibleActions[i].destinationTankOptions = []
          let optionsCount = 0
          for (const line in this.lineData) {
            const freshWaterTanks = this.lineData[line].tanks.filter(tank => tank.type === 'fresh-water-tank')
            const options = freshWaterTanks.map((tank, index) => (
              {
                id: optionsCount + (index + 1),
                description: tank.name + ' ' + (tank.settings.ros.ros_fill_water_tank_id.split('_')[2]),
                ros_irrigation_id: tank.settings.ros.ros_fill_water_tank_id,
              }
            ))
            optionsCount += freshWaterTanks.length
            this.wellPossibleActions[i].destinationTankOptions = this.wellPossibleActions[i].destinationTankOptions.concat(options)
          }
        }
      }
    },
    addWellNewAction () {
      this.clearFields()
      this.showAddNewModal = true
      this.selectedControlAction = 'add'
      this.selectedControlActionContext = 'well'

      this.modalTitle = this.$t('tanks.actions.add')
      this.modalMessage = ''
    },
    confirmAddAction () {
      if (this.selectedControlActionContext === 'well') {
        this.actionSelectionErrors = this.selectedTankAction.actionName ? '' : this.$t('irrigation.actionSelectionError')
        this.originTankErrors = this.selectedData.originTank ? '' : this.$t('irrigation.originTankError')
        this.destinationTankErrors = this.selectedData.destinationTank ? '' : this.$t('irrigation.destinationTankError')
        this.durationErrors = this.selectedData.duration ? [] : [this.$t('irrigation.durationError')]
        this.volumeErrors = this.selectedData.volume ? [] : [this.$t('irrigation.volumeError')]
      }
      if (!this.formReady) {
        console.log('form not ready')
        return
      }

      const emitEventName = `add_${this.selectedTankAction.actionName}_event`
      socketio.socket.emit(emitEventName, this.wellData.id, { data: this.setAddNewActionData() })
      this.launchToast(this.$t('tanks.actions.add') + ': ' + this.$t(`tanks.actions.${this.selectedTankAction.actionName}`))
      this.closeModal()
    },
    setAddNewActionData () {
      if (this.selectedControlActionContext === 'well') {
        return {
          originTank: this.selectedData.originTank.ros_irrigation_id,
          destinationTank: this.selectedData.destinationTank.ros_irrigation_id,
          duration: this.selectedData.duration,
          volume: this.selectedData.volume,
        }
      }
    },
    setWellAction (value) {
      this.showModal = true
      this.modalTitle = this.$t(`tanks.actions.${value.actionName}`)
      this.modalMessage = this.$t(`tanks.actions.${value.actionName}_${value.action}_message`)

      this.selectedControlActionContext = 'well'
      this.selectedControlActionLineId = this.wellData.id
      this.selectedControlAction = value.action
      this.selectedTankAction = value.actionName
      this.selectedActionKey = value.actionKey ? value.actionKey : ''
    },
    setAction (value) {
      this.showModal = true
      this.modalTitle = this.$t(`tanks.actions.${value.action}`)
      this.modalMessage = this.$t(`tanks.actions.${value.action}_message`)

      this.selectedControlActionContext = 'line'
      this.selectedControlActionLineId = value.lineId
      this.selectedControlAction = value.action
    },
    confirmAction () {
      // const emitEventName = `${this.selectedControlAction}_tank_event`
      const emitEventName = `${this.selectedControlAction}_irrigation`

      if (this.selectedControlActionContext === 'line') {
        socketio.socket.emit(emitEventName, this.selectedControlActionLineId, 'all')
      } else if (this.selectedControlActionContext === 'well') {
        socketio.socket.emit(emitEventName, this.selectedControlActionLineId, this.selectedActionKey)
      }
    },
    closeModal () {
      this.clearFields()
      this.$refs.modal.close()
      this.$refs.addmodal.close()
    },
    clearFields () {
      this.selectedControlActionContext = ''
      this.selectedControlActionLineId = ''
      this.selectedControlAction = ''
      this.selectedTankAction = ''
      this.selectedActionKey = ''

      this.actionSelectionErrors = ''
      this.originTankErrors = ''
      this.destinationTankErrors = ''
      this.durationErrors = []
      this.volumeErrors = []

      for (const data in this.selectedData) {
        this.selectedData[data] = data === 'overfill' ? false : ''
      }
    },
    getUnitFormated (units) {
      return styleHelpers.getOnlyUnitFormat(units)
    },
    launchToast (text) {
      this.showToast(
        text,
        {
          theme: 'none',
          position: 'top-center',
          duration: 4500,
          fullWidth: true,
        },
      )
    },
  },
  components: {
    OverviewCard,
    WellCard,
    IrrigationMap,
  },
  watch: {
    $route () {
      // this.initializeView()
    },
    notFound () {
      if (this.notFound) {
        this.loading = false
        this.$router.push({ path: '/404/page-not-found' })
      }
    },
    tabValue () {
      this.selectedControlGroupId = this.tabValue + this.activeFarm
      this.getControlGroupIrrigationMap(this.selectedControlGroupId, this.irrigationMapStartDate, this.irrigationMapEndDate)
    },
  },
  beforeDestroy () {
    for (let i = 0; i < this.lineData.length; i++) { // lines and well
      socketio.socket.emit('unsubscribe_from_context', [`irrigation_line_${this.lineData[i].id}`])
    }
  },
}
</script>

<style lang='scss'>
.va-modal {
  .va-modal__message {
    width: 700px;
  }

  .va-input__container__label,
  .va-select__label {
    font-family: var(--font-family-body);
    text-transform: none;
  }
}

.room-tabs {
  .va-tabs__wrapper {
    padding: 1rem;
  }
}
</style>
