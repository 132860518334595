<template>
  <div class="flex xs12">
    <va-card
      :title="name"
    >
      <template slot="actions">
        <!-- <va-button flat small icon="at-icon l_settings1" @click="settingsModal = !settingsModal"/> -->
        <va-button flat @click="addWellNewAction">{{ $t('tanks.actions.add') }}</va-button>
      </template>
      <div class="row">
        <!-- Volume -->
        <div class="flex xs6" v-if="volume">
          <template>
            <div class="content">
              <div class="content__main">
                <div class="content__main__top">
                  <va-icon :name="getDataIcon('volume')"></va-icon>
                  <span v-html="unitFormat(volume.val, volume.units)"></span>
                </div>
                <div class="content__main__bottom">
                  {{ $t(`data.volume`) }}
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- Volume flow-->
        <div class="flex xs6" v-if="volumeFlow">
          <template>
            <div class="content">
              <div class="content__main">
                <div class="content__main__top">
                  <va-icon :name="getDataIcon('volume_flow')"></va-icon>
                  <span v-html="unitFormat(volumeFlow.val, volumeFlow.units)"></span>
                </div>
                <div class="content__main__bottom">
                  {{ $t(`data.volume_flow`) }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- Progress -->
      <template v-for="(event, name) in events">
        <div class="row" v-if="event && event.event_key" :key="name">
          <div class="flex xs12 sm12">
            <span>{{ $t(`tanks.actions.${name}`) }}</span>
            <va-progress-bar v-model="event.progress.percent">
              <span :style="{visibility: event.progress.state !== 'Not running' ? 'visible' : 'hidden'}">
                {{ Math.round(event.progress.percent) }}%
              </span>
            </va-progress-bar>
            <div class="row">
              <div class="flex xs5 sm5">
                {{ $t('tanks.actions.status') }}: <span><b>{{ $t(`tanks.actions.${event.progress.state}`) }}</b></span>
              </div>
              <!-- Controls -->
              <div class="flex xs2 sm2" :key="'buttons_' + name">
                <div class="buttons">
                  <va-button v-if="event.progress.state === 'Paused'" flat icon="at-icon l_player_play" @click="setWellAction('play', name, event.event_key)"/>
                  <va-button v-if="event.progress.state === 'Running'" flat icon="at-icon l_player_pause" @click="setWellAction('pause', name, event.event_key)"/>
                  <va-button v-if="event.progress.state === 'Running' || event.progress.state === 'Paused'" flat icon="at-icon l_player_stop" @click="setWellAction('stop', name, event.event_key)"/>
                </div>
              </div>
              <div class="flex xs5 sm5 time__elapsed" :style="{'text-align': 'end'}">
                <!-- Volume -->
                <template v-if="event.progress.units === 'm3' && event.progress.state !== 'Not running'">
                  <div>
                    <span>{{ $t('data.volume') }}:</span>
                    <span><b>
                      {{ (event.progress.percent / event.progress.total) ? (Math.round(event.progress.percent * event.progress.total) / 100) : 0 }}/{{ Math.round(event.progress.total * 100) / 100 }}
                      {{ getUnitFormated(event.progress.units) }}
                    </b></span>
                  </div>
                  <!-- Time -->
                  <div class="mt-2">
                    <span>{{ $t('timeData.elapsedTime') }}: </span>
                    <span><b>{{ getElapsedTime(event.progress.elapsed_time) }}
                    </b></span>
                  </div>
                </template>

                <template v-else-if="event.progress.units === 'm3/h'">
                  <div>
                    <span>{{ $t('data.volume_flow') }}:</span>
                    <span><b>
                      {{ (event.progress.percent / event.progress.total) ? (Math.round(event.progress.percent * event.progress.total) / 100) : 0 }}/{{ Math.round(event.progress.total * 100) / 100 }}
                      {{ getUnitFormated(event.progress.units) }}
                    </b></span>
                  </div>
                  <!-- Time -->
                  <div class="mt-2">
                    <span>{{ $t('timeData.elapsedTime') }}: </span>
                    <span><b>{{ getElapsedTime(event.progress.elapsed_time) }}
                    </b></span>
                  </div>
                </template>

                <template v-else-if="(event.progress.units === 's' || event.progress.units === 'sec')">
                  <div class="time__only" v-if="event.progress.state !== 'Not running'">
                    <span>{{ $t('timeData.elapsedTime') }}: </span>
                    <span><b>
                      {{ getTimeFormated(event.progress.total * event.progress.percent / 100) }}/{{ getTimeFormated(event.progress.total) }}{{event.progress.total > 3600 ? 'H' : ' min' }}
                    </b></span>
                  </div>
                </template>
                <template v-else>
                  <div class="time__only"></div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </va-card>

    <va-modal
      v-model="settingsModal"
      :noOutsideDismiss="false"
      :hideDefaultActions="true"
      size="large"
      ref="settingsModal"
    >

      <template slot="actions">
        <div class="row">
          <div class="flex xs4">
          </div>
          <div class="flex xs8 text-right">
            <va-button @click="cancel()" flat color="gray">
              {{  $t('modal.cancel') }}
            </va-button>
            <va-button @click="confirm()" color="success">
              {{ $t('modal.confirm') }}
            </va-button>
          </div>
        </div>
      </template>

      <at-settings-form
        :title="`${$t('growing.settings')}`"
        :subtitle="name"
        :settings-array="settings"
        :fresh-water-tanks-options="freshWaterTanksOptions"
      />

    </va-modal>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { styleHelpers } from '@/services/atmosphere-ui'
import AtSettingsForm from './../../at-settings-form/AtSettingsForm'

export default {
  name: 'well-card',
  data () {
    return {
      settingsModal: false,
    }
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    volume: {
      type: Object,
      default () {
        return {
          units: 'm3',
          time_stamp: '',
          val: null,
          ref: null,
          level: 0,
        }
      },
    },
    volumeFlow: {
      type: Object,
      default () {
        return {
          units: 'm3/h',
          time_stamp: '',
          val: null,
          ref: null,
          level: 0,
        }
      },
    },
    events: {
      type: Object,
      default () {
        return {}
      },
    },
    settings: {
      type: Object,
      default () {
        return {}
      },
    },
    freshWaterTanksOptions: {
      type: Array,
      default () {
        return []
      },
    },
  },
  methods: {
    getDataIcon (dataName) {
      return styleHelpers.growingIcon('irrigation', dataName)
    },
    unitFormat (value, units) {
      return styleHelpers.unitFormat(value, units)
    },
    addWellNewAction () {
      this.$parent.addWellNewAction()
    },
    setWellAction (selectedAction, name, key) {
      this.$emit('setWellAction', {
        action: selectedAction,
        actionName: name,
        actionKey: key,
      })
    },
    getElapsedTime (elapsedTime) {
      const time = elapsedTime.split(' (')[0]
      const timeLenght = time.split(':').length

      if (timeLenght <= 2) {
        return time + ' min'
      } else if (timeLenght > 2) {
        return time + 'H'
      } else {
        return time
      }
    },
    getUnitFormated (units) {
      return styleHelpers.getOnlyUnitFormat(units)
    },
    getTimeFormated (seconds) {
      return seconds > 3600 ? moment.utc(seconds * 1000).format('HH:mm:ss') : moment.utc(seconds * 1000).format('mm:ss')
    },
    cancel () {
      this.$refs.settingsModal.cancel()
    },
    confirm () {
      this.$emit('saveWellSettings', this.id)
      this.$refs.settingsModal.ok()
    },
  },
  components: {
    AtSettingsForm,
  },
}
</script>

<style lang="scss">
.buttons {
  display: flex;
  justify-content: center;

  .va-button {
    padding: 0.2rem;
    margin: 0.2rem;
    font-size: 1.5rem;
  }
}

.time__elapsed {
  text-align: end;
  padding: 0.5rem 1.25rem;

  .time__only {
    min-height: 3.5rem;
  }
}
</style>
